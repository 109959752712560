import fetchInviteCode from '@lyra/core/api/fetchInviteCode'
import fetchPoints from '@lyra/core/api/fetchPoints'
import { Address } from 'viem'

import { InstrumentType } from '../constants/instruments'
import {
  EMPTY_POINTS,
  LevelConfig,
  Points,
  PointsData,
  PointsEpoch,
  pointsEpochs,
} from '../constants/points'
import {
  REWARDS_BLOCK_ADDRESSES,
  TRADING_REWARDS_IGNORE_ADDRESSES,
  VAULT_REWARDS_IGNORE_ADDRESSES,
} from '../constants/walletLists'

export const getLevelFromPoints = (pointsEpoch: PointsEpoch, totalPoints: number): LevelConfig => {
  const levelConfig = pointsEpoch.levels.findLast((config) => {
    return totalPoints >= config.cutoff
  }, [])
  if (!levelConfig) {
    // first level
    return pointsEpoch.levels[0]
  }
  return levelConfig
}

export const getPointsToNextLevel = (
  pointsEpoch: PointsEpoch,
  totalPoints: number
): {
  level: LevelConfig
  nextLevel?: LevelConfig
  pointsToNextLevel?: number
  levelProgress?: number
} => {
  const level = getLevelFromPoints(pointsEpoch, totalPoints)
  const nextLevelId = level.id + 1
  const nextLevel = pointsEpoch.levels.find((config) => config.id === nextLevelId)
  if (!nextLevel) {
    // user is at highest level
    return { level }
  }
  const pointsToNextLevel = Math.max(nextLevel.cutoff - totalPoints, 0)
  const totalLevelPoints = nextLevel.cutoff - level.cutoff
  const levelProgress = totalLevelPoints
    ? (totalLevelPoints - pointsToNextLevel) / totalLevelPoints
    : 0

  return {
    level,
    nextLevel,
    pointsToNextLevel,
    levelProgress,
  }
}

export const formatLevel = ({ levelName, sublevel }: LevelConfig) =>
  `${levelName} ${sublevel === 1 ? 'I' : sublevel === 2 ? 'II' : sublevel === 3 ? 'III' : sublevel}`

export const getPointsEpoch = (label: string) => {
  return pointsEpochs.find((epoch) => epoch.label === label)
}

export const getPointsEpochForTimestamp = (now: number) => {
  return (
    pointsEpochs.find((epoch) => epoch.endTimestamp > now && epoch.startTimestamp < now) ??
    pointsEpochs[pointsEpochs.length - 1]
  )
}

export const getIsUserPointsFlagged = (flag: string) => {
  return flag !== 'valid' && flag !== undefined
}

export const getTotalPoints = (points: Points) => {
  return Object.values(points).reduce((sum, pt) => sum + pt, 0)
}

export const getTwitterIntentUrl = (shareableInviteLink: string) => {
  return `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    `Trade options and perps on Derive to earn DRV, OP and USDC: ${shareableInviteLink}`
  )}`
}

export const fetchPointsData = async (
  pointsEpoch: PointsEpoch,
  address: Address
): Promise<PointsData> => {
  try {
    const pointsRes = await fetchPoints(pointsEpoch.label, address)

    const isMmProgram = TRADING_REWARDS_IGNORE_ADDRESSES.has(address)
    const isVaultProgram = VAULT_REWARDS_IGNORE_ADDRESSES.has(address)
    const isBlocked = REWARDS_BLOCK_ADDRESSES.has(address)

    const points: Points = Object.keys(EMPTY_POINTS).reduce(
      (dict, _key) => {
        const key = _key as keyof Points
        if (isMmProgram && ['trading', 'referral', 'account_value'].includes(key)) {
          // MMs not eligible for referral or account_value points
          // MMs have trading points calculated retroactively after rebates so we zero their points in ui
          return dict
        }

        if (isVaultProgram && !['trading', 'referral'].includes(key)) {
          // Vault program not eligible for account_value or vault points
          return dict
        }

        if (isBlocked) {
          // Blocked users not eligible for any points
          return dict
        }

        const points = +pointsRes.result.points[key]
        return { ...dict, [key]: !isNaN(points) ? points : 0 }
      },
      { ...EMPTY_POINTS }
    )

    const totalPoints = getTotalPoints(points)

    return {
      isRegistered: true,
      isMmProgram,
      isVaultProgram,
      isBlocked,
      totalPoints,
      points,
      pointsRaw: pointsRes?.result,
    }
  } catch (error) {
    // fails when user is not registered
    return {
      isRegistered: false,
    }
  }
}

export const tryFetchInviteCode = async (address: Address): Promise<string | undefined> => {
  try {
    const res = await fetchInviteCode(address)
    return res.result.code
  } catch (error) {
    return
  }
}

export const getTradingPointsMultiplier = (instrumentType: InstrumentType, isRfq: boolean) => {
  if (instrumentType === InstrumentType.Perps) {
    return 2
  }
  if (isRfq) {
    return 2
  }
  return 1
}
