import Button from '@lyra/core/components/Button'
import { Check, Copy, EyeOff, Power, Settings } from '@lyra/core/components/Icon'
import formatTruncatedAddress from '@lyra/core/utils/formatTruncatedAddress'
import NavDropdownButton from '@lyra/web/components/common/NavDropdownButton'
import { PageId } from '@lyra/web/constants/pages'
import useAuth from '@lyra/web/hooks/useAuth'
import useToast from '@lyra/web/hooks/useToast'
import { getPagePath } from '@lyra/web/utils/pages'
import { useRouter } from 'next/navigation'
import React, { useCallback, useState } from 'react'

import SignInButton from '../SignInButton'

const NavAccountDropdownButton = () => {
  const router = useRouter()

  const {
    isAuthenticated,
    isMaybeAuthenticated,
    isAuthenticating,
    mock,
    user,
    logout,
    deleteMockSessionDONOTUSE,
  } = useAuth()

  const { createToast } = useToast()

  const [isLoading, setIsLoading] = useState(false)

  const handleCopyAddress = useCallback(() => {
    if (!user?.ownerAddress) {
      return
    }
    navigator.clipboard.writeText(user.ownerAddress)
    createToast({
      title: 'Copied Address',
      description: `Copied ${formatTruncatedAddress(user.ownerAddress)} to clipboard`,
      icon: <Check />,
      color: 'green',
    })
  }, [user?.ownerAddress, createToast])

  if (isAuthenticating || isMaybeAuthenticated) {
    return <Button isLoading />
  }

  if (!isAuthenticated) {
    return <SignInButton />
  }

  const username = user.username ? user.username : formatTruncatedAddress(user.ownerAddress)

  if (mock) {
    return (
      <Button
        label={username}
        icon={<EyeOff />}
        color="amber"
        onPress={deleteMockSessionDONOTUSE}
      />
    )
  }

  return (
    <NavDropdownButton label={username} isLoading={isLoading}>
      <NavDropdownButton.ListItem
        label="COPY ADDRESS"
        sublabel={formatTruncatedAddress(user.ownerAddress)}
        rightIcon={<Copy />}
        onPress={handleCopyAddress}
      />
      <NavDropdownButton.ListItem
        label="SETTINGS"
        rightIcon={<Settings />}
        onPress={() => router.push(getPagePath({ page: PageId.Settings }))}
      />
      <NavDropdownButton.ListItem
        label="Disconnect"
        rightIcon={<Power />}
        onPress={async () => {
          setIsLoading(true)
          await logout()
          setIsLoading(false)
        }}
      />
    </NavDropdownButton>
  )
}

export default NavAccountDropdownButton
