'use client'

import { bigNumberToNumberUNSAFE } from '@lyra/core/utils/bigNumberToNumberUNSAFE'
import { BridgeOptions } from '@lyra/web/constants/bridge'
import { DepositNetwork } from '@lyra/web/constants/chains'
import { PageId } from '@lyra/web/constants/pages'
import { depositTokenConfig, DepositTokenId } from '@lyra/web/constants/tokens'
import { TransactionOptions } from '@lyra/web/constants/transactions'
import useAuth from '@lyra/web/hooks/useAuth'
import useCollaterals from '@lyra/web/hooks/useCollaterals'
import useDepositQuote from '@lyra/web/hooks/useDepositQuote'
import useTransaction from '@lyra/web/hooks/useTransaction'
import { getDepositAmountWithFees, getSupportedDepositTokens } from '@lyra/web/utils/bridge'
import { getPagePath } from '@lyra/web/utils/pages'
import {
  formatDepositTokenSymbol,
  formatTokenSymbol,
  getCollateralForDepositToken,
  getDefaultNetworkForToken,
  getTokenForDepositToken,
  sortDepositTokens,
} from '@lyra/web/utils/tokens'
import { getDepositTokenBalance } from '@lyra/web/utils/wallet'
import { useCallback, useEffect, useMemo, useState } from 'react'

import WalletTransactionModal from '../WalletTransactionModal'
import DepositModalRows from './DepositModalRows'

/**
 * Hierarchy:
 * - Network informs what deposit tokens are available
 */

type DepositState = {
  network: DepositNetwork
  depositToken: DepositTokenId
}

const DEFAULT_DEPOSIT_STATE: DepositState = {
  network: DepositNetwork.Ethereum,
  depositToken: DepositTokenId.USDC,
}

const POLL_BALANCES_INTERVAL = 15 * 1000 // 15 seconds

type Props = {
  onClose: () => void
  defaultDepositToken?: DepositTokenId
}

export default function DepositModal({ onClose, defaultDepositToken }: Props) {
  const { depositBalances, bridgeToLyraChain, mutateBalances } = useTransaction()

  // Refresh balances more frequently when deposit modal is open
  useEffect(() => {
    const interval = setInterval(mutateBalances, POLL_BALANCES_INTERVAL)
    return () => {
      clearInterval(interval)
    }
  }, [mutateBalances])

  // Determine initial deposit state
  const defaultDepositState: DepositState = useMemo(() => {
    if (defaultDepositToken) {
      const defaultToken = getTokenForDepositToken(defaultDepositToken)
      const defaultNetwork = getDefaultNetworkForToken(defaultToken)
      if (!defaultNetwork) {
        return DEFAULT_DEPOSIT_STATE
      }
      return {
        depositToken: defaultDepositToken,
        network: defaultNetwork,
      }
    }
    return DEFAULT_DEPOSIT_STATE
  }, [defaultDepositToken])

  const [depositState, _setDepositState] = useState<DepositState>(defaultDepositState)

  // TODO: @earthtojake fix or remove gelato functionality, currently defaulting to native deposits
  const [isForcedNative, setIsForcedNative] = useState(true)

  const { network: selectedNetwork, depositToken: selectedDepositToken } = depositState

  const selectedBalance = getDepositTokenBalance(
    depositBalances,
    selectedNetwork,
    selectedDepositToken
  )

  const [depositAmountInput, setDepositAmountInput] = useState<bigint>(BigInt(0))

  const { user } = useAuth()

  /**
   * Deposit type + fee quote
   */
  const {
    data: depositQuote,
    error,
    isLoading,
  } = useDepositQuote(selectedNetwork, selectedDepositToken, depositAmountInput, isForcedNative)

  const { depositAmountWithFees, maxDepositAmount } = getDepositAmountWithFees(
    depositAmountInput,
    selectedBalance,
    selectedDepositToken,
    selectedNetwork,
    depositQuote
  )
  const depositAmount = depositAmountInput

  const isInsufficientBalance = depositAmountWithFees
    ? depositAmountWithFees > selectedBalance
    : undefined

  /**
   * Check Lyra Chain deposit cap
   * - USDC has no deposit cap
   */
  const isInsufficientDepositCap = depositQuote
    ? depositAmount > depositQuote.availableDepositCap
    : false

  const wrappedDepositFn = useCallback(
    (options: TransactionOptions) => {
      if (!depositQuote) {
        throw new Error('Failed to quote fees for deposit')
      }

      if (isInsufficientBalance) {
        throw new Error('Insufficient balance')
      }
      const bridgeOptions: BridgeOptions = depositQuote.isNative
        ? {
            isNative: true,
            // TODO: @earthtojake make this a setting
            isMaxApproval: true,
          }
        : {
            isNative: false,
            isSponsored: depositQuote.isSponsored,
            maxFee: depositQuote.fee,
          }

      // Deposit to existing subaccount
      return bridgeToLyraChain(
        selectedNetwork,
        selectedDepositToken,
        depositAmount,
        bridgeOptions,
        options
      )
    },
    [
      depositQuote,
      isInsufficientBalance,
      selectedNetwork,
      selectedDepositToken,
      depositAmount,
      bridgeToLyraChain,
    ]
  )

  const handleChangeToken = useCallback(
    (depositToken: DepositTokenId) => {
      if (depositToken === selectedDepositToken) {
        return
      }

      const supportedDepositTokens = getSupportedDepositTokens(selectedNetwork, user)

      if (!supportedDepositTokens.find((t) => t === depositToken)) {
        console.warn('deposit token', depositToken, 'not supported on network', selectedNetwork)
        return
      }

      setDepositAmountInput(BigInt(0))
      _setDepositState((state) => ({ ...state, network: selectedNetwork, depositToken }))
    },
    [selectedNetwork, selectedDepositToken, user]
  )

  const handleChangeNetwork = useCallback(
    (network: DepositNetwork) => {
      if (network === selectedNetwork) {
        return
      }
      const supportedDepositTokens = getSupportedDepositTokens(network, user)
      if (!supportedDepositTokens.find((t) => t === selectedDepositToken)) {
        // current selected token is not on new network, switch to default
        const defaultDepositToken = getSupportedDepositTokens(network, user)[0]
        // Note: this case should not happen, i.e. no supported deposit tokens for network
        if (!defaultDepositToken) {
          console.warn('no default deposit token for network', network)
          return
        }

        setDepositAmountInput(BigInt(0))
        _setDepositState((state) => ({ ...state, network, depositToken: defaultDepositToken }))
        return
      } else {
        // preserve selected token
        _setDepositState((state) => ({ ...state, network, depositToken: selectedDepositToken }))
      }
    },
    [user, selectedNetwork, selectedDepositToken]
  )

  const collaterals = useCollaterals()

  const supportedTokens = useMemo(() => {
    return sortDepositTokens(getSupportedDepositTokens(selectedNetwork, user)).sort((a, b) => {
      const aCollateral = getCollateralForDepositToken(a)
      const bCollateral = getCollateralForDepositToken(b)

      const aSpotPrice = collaterals[aCollateral].spotPrice
      const bSpotPrice = collaterals[bCollateral].spotPrice

      const aBalanceNum = bigNumberToNumberUNSAFE(
        depositBalances[selectedNetwork][a],
        depositTokenConfig[a].decimals
      )
      const bBalanceNum = bigNumberToNumberUNSAFE(
        depositBalances[selectedNetwork][b],
        depositTokenConfig[b].decimals
      )

      return bBalanceNum * bSpotPrice - aBalanceNum * aSpotPrice
    })
  }, [selectedNetwork, user, collaterals, depositBalances])

  const depositTokenSymbol = formatDepositTokenSymbol(selectedDepositToken)
  const token = getTokenForDepositToken(selectedDepositToken)
  const tokenSymbol = formatTokenSymbol(token)

  return (
    <WalletTransactionModal
      onClose={onClose}
      transaction={wrappedDepositFn}
      reviewTitle={'Deposit'}
      loggerAction="deposit"
      reviewContent={
        <DepositModalRows
          networks={Object.values(DepositNetwork)}
          selectedNetwork={selectedNetwork}
          selectedToken={selectedDepositToken}
          supportedTokens={supportedTokens}
          depositQuote={depositQuote}
          isDepositQuoteLoading={isLoading}
          error={error}
          isForcedNative={isForcedNative}
          balances={depositBalances[selectedNetwork]}
          isInsufficientBalance={isInsufficientBalance}
          depositAmount={depositAmountInput}
          maxDepositAmount={maxDepositAmount}
          onChangeDepositAmount={setDepositAmountInput}
          onChangeNetwork={handleChangeNetwork}
          onChangeToken={handleChangeToken}
          onChangeIsForcedNative={(isNative) => setIsForcedNative(isNative)}
        />
      }
      notice={
        error
          ? {
              message: error.message,
              status: 'error',
            }
          : depositQuote &&
            !depositQuote.isNative &&
            (depositQuote.notSponsoredReason === 'gelato-empty' ||
              depositQuote.notSponsoredReason === 'sponsor-helper-empty')
          ? {
              message:
                'Discounted deposits are unavailable at this time. Please switch Fee Token to ETH.',
              status: 'error',
            }
          : !isInsufficientBalance && isInsufficientDepositCap
          ? {
              message: `Deposit limit for ${depositTokenSymbol} has been reached.`,
              status: 'error',
            }
          : depositTokenSymbol !== tokenSymbol
          ? {
              message: `Your ${depositTokenSymbol} will be represented as ${tokenSymbol} on Derive.`,
            }
          : undefined
      }
      reviewButtonLabel={
        !!error
          ? 'Something went wrong'
          : isLoading || depositAmountWithFees === undefined || !depositAmountInput
          ? 'Enter Amount'
          : isInsufficientBalance
          ? 'Insufficient Balance'
          : isInsufficientDepositCap
          ? 'Insufficient Capacity'
          : 'Deposit'
      }
      isDisabled={isLoading || !!error || !depositAmount || isInsufficientBalance}
      completeButtonProps={{
        label: 'View Transactions',
        path: getPagePath({ page: PageId.Balances }),
      }}
    />
  )
}
