import { XStack } from '@lyra/core/components'
import SpanText from '@lyra/core/components/SpanText'
import VideoLink from '@lyra/core/components/VideoLink'
import VideoText from '@lyra/core/components/VideoText'
import formatPercentage from '@lyra/core/utils/formatPercentage'
import formatUSD from '@lyra/core/utils/formatUSD'
import NextVideoLink from '@lyra/web/components/common/NextVideoLink'
import { PageId } from '@lyra/web/constants/pages'
import useMarkets from '@lyra/web/hooks/useMarkets'
import useSettings from '@lyra/web/hooks/useSettings'
import { getActiveOptionMarketIds } from '@lyra/web/utils/markets'
import { MARQUEE_ITEMS, MessageMarqueeItem, SpotMarqueeItem } from '@lyra/web/utils/marquee'
import { getPagePath } from '@lyra/web/utils/pages'
import { useMemo } from 'react'

const SpotMarqueeLink = ({ marketId }: SpotMarqueeItem) => {
  const marketData = useMarkets()[marketId]

  const hasOptionsMarket = useMemo(() => getActiveOptionMarketIds().includes(marketId), [marketId])

  const primaryText = `${marketData.marketId} ${formatUSD(marketData.spotPrice, {
    dps: 2,
    showCommas: true,
  })}`

  const isIncrease = marketData.spotPrice24hPctChange >= 0

  return (
    <NextVideoLink
      size="sm"
      href={getPagePath(
        hasOptionsMarket ? { page: PageId.Options, marketId } : { page: PageId.Perps, marketId }
      )}
      label={
        <>
          {primaryText}&nbsp;
          <SpanText color={isIncrease ? 'green' : 'red'}>
            {isIncrease ? '▲' : '▼'}&nbsp;
            {formatPercentage(Math.abs(marketData.spotPrice24hPctChange), {
              dps: 2,
              showSign: false,
            })}
          </SpanText>
        </>
      }
    />
  )
}

const MessageMarqueeLink = ({ ...item }: MessageMarqueeItem) => {
  return (
    <XStack key={item.id} space="$1">
      {item.messages.map((message, idx) =>
        message.href ? (
          message.href.startsWith('/') ? (
            <NextVideoLink
              size="sm"
              key={idx}
              href={message.href as `/${string}`}
              label={message.message.toUpperCase()}
            />
          ) : (
            <VideoLink
              size="sm"
              key={idx}
              href={message.href}
              target={message.target}
              label={message.message.toUpperCase()}
            />
          )
        ) : (
          <VideoText size="sm" key={idx}>
            {message.message}
          </VideoText>
        )
      )}
    </XStack>
  )
}

export default function MarqueeItems() {
  const [settings] = useSettings()

  return (
    <XStack alignItems="center" gap="$8" paddingHorizontal="$4">
      {MARQUEE_ITEMS.map((item) =>
        settings.isMarqueePaused ? null : item.type === 'message' ? (
          <MessageMarqueeLink key={item.id} {...item} />
        ) : item.type === 'spot-price' ? (
          <SpotMarqueeLink key={item.id} {...item} />
        ) : null
      )}
    </XStack>
  )
}
