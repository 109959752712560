import { MAX_INT, WEI_DECIMALS } from '@lyra/core/constants/contracts'
import toBigNumber from '@lyra/core/utils/toBigNumber'
import { useEffect, useMemo } from 'react'
import useSWR from 'swr'
import { Address } from 'viem'

import erc20Abi from '../abis/erc20Abi'
import wrappedErc20Abi from '../abis/wrappedErc20Abi'
import { SPONSORED_DEPOSIT_TIME_THRESHOLD_MS } from '../constants/bridge'
import { DepositNetwork } from '../constants/chains'
import { lyraClient } from '../constants/client'
import { lyraContractAddresses } from '../constants/contracts'
import { isMainnet, isTestnet } from '../constants/env'
import { CollateralId, DepositTokenId } from '../constants/tokens'
import { fetchDepositTokensApy } from '../utils/apy'
import {
  fetchEstimateGelatoBridgeFee,
  fetchEstimateNativeBridgeGasFee,
  fetchSocketBridgeFee,
  getDepositContractAddresses,
} from '../utils/bridge'
import { fetchBridges } from '../utils/client/deposits'
import {
  fetchIsEligibleForSponsoredBridge,
  GelatoSponsoredResponse,
  getGelatoMinDepositForSponsorship,
  getIsGelatoBridgeSupported,
} from '../utils/gelato'
import { getNetworkClient } from '../utils/rpc'
import {
  getCollateralAddress,
  getCollateralForDepositToken,
  getLyraTokenAddress,
  getTokenDecimals,
  getTokenForDepositToken,
} from '../utils/tokens'
import useAuth from './useAuth'

export type DepositQuote =
  | {
      isNative: true
      fee: bigint
      feeToken: DepositTokenId
      availableDepositCap: bigint
    }
  | ({
      isNative: false
      fee: bigint
      feeToken: DepositTokenId
      availableDepositCap: bigint
      sponsoredHelperHasEth: boolean
    } & GelatoSponsoredResponse)

const REFRESH_INTERVAL_MS = 30_000

const getMinSponsoredHelperEthBalance = (network: DepositNetwork) => {
  switch (network) {
    case DepositNetwork.Ethereum:
      return toBigNumber(0.00015, WEI_DECIMALS)
    case DepositNetwork.Arbitrum:
    case DepositNetwork.Base:
    case DepositNetwork.Optimism:
      return toBigNumber(0.000002, WEI_DECIMALS)
  }
}

const fetchIsEligible = async (
  network: DepositNetwork,
  token: DepositTokenId
): Promise<GelatoSponsoredResponse> => {
  if (isTestnet) {
    // always sponsored on testnet
    return {
      isSponsored: true,
      notSponsoredReason: undefined,
    }
  }
  // TODO: @earthtojake use block timestamp or another clock
  const recentBridges = await fetchBridges(Date.now() - SPONSORED_DEPOSIT_TIME_THRESHOLD_MS)
  // Note: we use MAX_AMOUNT here to always pass the eligibility test
  // this avoids useless re-fetches when amount updates, which is done locally in the hook below
  return await fetchIsEligibleForSponsoredBridge(network, token, recentBridges, MAX_INT)
}

// Available deposit cap of corresponding token/collateral on Lyra Chain
const fetchAvailableDepositCap = async (depositToken: DepositTokenId) => {
  const collateralId = getCollateralForDepositToken(depositToken)
  // USDC has no deposit cap
  if (collateralId === CollateralId.USDC) {
    return BigInt(Number.MAX_SAFE_INTEGER)
  }
  const tokenId = getTokenForDepositToken(depositToken)
  // totalCap in 10^18, totalSupply in token decimal
  const [totalCap, totalSupply] = await Promise.all([
    lyraClient.readContract({
      address: getCollateralAddress(collateralId),
      abi: wrappedErc20Abi,
      functionName: 'totalPositionCap',
      args: [lyraContractAddresses.standardManager],
    }),
    lyraClient.readContract({
      address: getLyraTokenAddress(tokenId),
      abi: erc20Abi,
      functionName: 'totalSupply',
    }),
  ])
  // Adjust to token decimals
  const tokenTotalCap = totalCap / BigInt(10 ** (WEI_DECIMALS - getTokenDecimals(tokenId)))
  return tokenTotalCap - totalSupply
}

const fetchSponsoredHelperEthBalance = async (network: DepositNetwork, token: DepositTokenId) => {
  const sponsoredHelper = getDepositContractAddresses(network, token).gelatoSponsoredHelper
  const networkClient = await getNetworkClient(network)
  return sponsoredHelper ? networkClient.getBalance({ address: sponsoredHelper }) : BigInt(0)
}

const fetchNetworkQuote = async (
  network: DepositNetwork,
  ownerAddress: Address,
  token: DepositTokenId,
  isNative: boolean
): Promise<DepositQuote> => {
  if (!isNative) {
    const [sponsoredRes, estFee, availableDepositCap, helperEthBalance] = await Promise.all([
      fetchIsEligible(network, token),
      fetchEstimateGelatoBridgeFee(ownerAddress, network, token),
      fetchAvailableDepositCap(token),
      fetchSponsoredHelperEthBalance(network, token),
    ])

    return {
      isNative: false,
      fee: estFee,
      feeToken: token,
      availableDepositCap,
      sponsoredHelperHasEth: helperEthBalance >= getMinSponsoredHelperEthBalance(network),
      ...sponsoredRes,
    }
  } else {
    const [estGasFee, socketFee, availableDepositCap, depositTokensApy] = await Promise.all([
      fetchEstimateNativeBridgeGasFee(ownerAddress, network),
      fetchSocketBridgeFee(network, token),
      fetchAvailableDepositCap(token),
      fetchDepositTokensApy(),
    ])

    const fee = socketFee + estGasFee
    return {
      isNative: true,
      fee,
      feeToken: DepositTokenId.ETH,
      availableDepositCap,
    }
  }
}

const fetcher = async (
  ownerAddress: Address,
  network: DepositNetwork,
  token: DepositTokenId,
  forceNative: boolean
) => {
  const isNative = forceNative ? forceNative : !getIsGelatoBridgeSupported(network, token)
  return fetchNetworkQuote(network, ownerAddress, token, isNative)
}

export default function useDepositQuote(
  network: DepositNetwork,
  token: DepositTokenId,
  amount: bigint,
  forceNative: boolean
): {
  data: DepositQuote | undefined
  error: Error
  isLoading: boolean
} {
  const { user } = useAuth()
  const ownerAddress = user?.ownerAddress
  const { data, isLoading, error } = useSWR(
    ownerAddress ? ['DepositQuote', ownerAddress, network, token, forceNative] : null,
    ([_, ownerAddress, network, token, forceNative]) =>
      fetcher(ownerAddress, network, token, forceNative),
    {
      refreshInterval: REFRESH_INTERVAL_MS,
    }
  )

  const dataWithAmountCheck: DepositQuote | undefined = useMemo(() => {
    if (data) {
      if (data && !data.isNative && data.isSponsored && isMainnet) {
        // Check sponsor contract has balance
        if (!data.sponsoredHelperHasEth) {
          return {
            ...data,
            isSponsored: false,
            notSponsoredReason: 'sponsor-helper-empty',
          }
          // Check amount is above threshold
        } else if (amount < getGelatoMinDepositForSponsorship(network as DepositNetwork, token)) {
          return {
            ...data,
            isSponsored: false,
            notSponsoredReason: 'min-amount',
          }
        }
      }
    }
    return data
  }, [amount, data, token, network])

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  return useMemo(
    () => ({
      data: dataWithAmountCheck ? dataWithAmountCheck : undefined,
      isLoading,
      error,
    }),
    [error, dataWithAmountCheck, isLoading]
  )
}
