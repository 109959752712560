import useHover from '@lyra/core/hooks/useHover'
import isExternalURL from '@lyra/core/utils/isExternalURL'
import React, { useCallback, useState } from 'react'
import { Anchor, FontSizeTokens, useTheme, XStack } from 'tamagui'

import Icon, { ArrowUpRight } from './Icon'
import { VideoTextProps } from './VideoText'

export type VideoLinkProps = {
  label: React.ReactNode
  href?: string
  target?: string
  isActive?: boolean
  showNudge?: boolean
} & Omit<VideoTextProps, 'color' | 'children'>

const VideoLink = React.forwardRef<HTMLAnchorElement, VideoLinkProps>(
  (
    { label, href, target, isActive: isActiveInput, size, isBold, showNudge, ...textProps },
    ref
  ) => {
    const { isHovering, handleHoverIn, handleHoverOut } = useHover()

    const theme = useTheme()

    const isActive = isHovering || isActiveInput
    const color = isActive ? '$primaryText' : '$secondaryText'

    const isExternalHref = !!href && (isExternalURL(href) || target === '_blank')

    const sizeToken = ('$' + size) as FontSizeTokens

    const [glitchText, setGlitchText] = useState<string>('')

    const glitchChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const glitchDuration = 500 // duration in milliseconds

    // Function to randomly replace characters
    const getRandomChar = () => glitchChars[Math.floor(Math.random() * glitchChars.length)]

    const wrappedHandleHoverIn = useCallback(() => {
      if (!glitchText && typeof label === 'string') {
        const glitchInterval = 25 // Glitch interval in milliseconds
        const totalDuration = 250 // Total glitch duration in milliseconds
        let elapsed = 0

        // Function to apply the glitch effect
        const applyGlitch = () => {
          const glitchedTextArray = label.split('').map(() => getRandomChar())
          setGlitchText(glitchedTextArray.join(''))
        }

        // Start the glitch effect at regular intervals
        const intervalId = setInterval(() => {
          applyGlitch()
          elapsed += glitchInterval

          // Stop the glitch effect after the total duration
          if (elapsed >= totalDuration) {
            clearInterval(intervalId)
            setGlitchText('') // Revert to original text
          }
        }, glitchInterval)
      }

      handleHoverIn()
    }, [label, glitchText, handleHoverIn])

    return (
      <Anchor
        {...textProps}
        onHoverIn={wrappedHandleHoverIn}
        onHoverOut={handleHoverOut}
        href={href ? href : undefined}
        target={target}
        color={color}
        fontFamily={isBold ? '$specialBold' : '$special'}
        size={size ? sizeToken : undefined}
        letterSpacing={size ? sizeToken : undefined}
        textDecorationLine="none"
        cursor="pointer"
        alignItems="center"
        display="inline-flex"
        ref={ref}
        style={{ position: 'relative' }}
      >
        {glitchText ? glitchText : label}
        {isExternalHref ? (
          <>
            <XStack width="2px" />
            <Icon size={12} color={color} icon={<ArrowUpRight strokeWidth={3} />} />
          </>
        ) : null}
        {showNudge ? (
          <XStack
            style={{ position: 'absolute', top: 2, right: -6 }}
            width={6}
            height={6}
            borderRadius="$1"
            background={`linear-gradient(${theme.red?.get()}, ${theme.amber?.get()})`}
          />
        ) : null}
      </Anchor>
    )
  }
)

VideoLink.displayName = 'Link'

export default VideoLink
