import { useMemo } from 'react'

import { getTabs, TabDetails, TabId } from '../utils/tabs'
import useSelectedCollateral from './useSelectedCollateral'
import useSelectedMarket from './useSelectedMarket'
import useSettings from './useSettings'

const useTabs = (): Record<TabId, TabDetails> => {
  const [settings] = useSettings()

  const selectedMarket = useSelectedMarket()
  const selectedCollateral = useSelectedCollateral()

  return useMemo(() => {
    return getTabs({
      defaultOptionsMarket: settings?.defaultOptionsMarket ?? selectedMarket,
      defaultPerpsMarket: settings?.defaultPerpsMarket ?? selectedMarket,
      defaultSpotCollateral: settings?.defaultSpotCollateral ?? selectedCollateral,
    })
  }, [
    selectedMarket,
    selectedCollateral,
    settings?.defaultOptionsMarket,
    settings?.defaultPerpsMarket,
    settings?.defaultSpotCollateral,
  ])
}

export default useTabs
