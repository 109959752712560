import BodyText from '@lyra/core/components/BodyText'
import Button from '@lyra/core/components/Button'
import DropdownButton from '@lyra/core/components/DropdownButton'
import { ArrowLeftRight } from '@lyra/core/components/Icon'
import BigNumberInput from '@lyra/core/components/Input/BigNumberInput'
import LabelText from '@lyra/core/components/LabelText'
import Link from '@lyra/core/components/Link'
import Notice from '@lyra/core/components/Notice'
import Section from '@lyra/core/components/Section'
import SpanText from '@lyra/core/components/SpanText'
import Tag from '@lyra/core/components/Tag'
import TextAmountUpdate from '@lyra/core/components/TextAmountUpdate'
import TooltipText from '@lyra/core/components/TooltipText'
import { bigNumberToNumberUNSAFE } from '@lyra/core/utils/bigNumberToNumberUNSAFE'
import toBigNumber from '@lyra/core/utils/toBigNumber'
import NetworkIcon from '@lyra/web/components/common/NetworkIcon'
import TokenIcon from '@lyra/web/components/common/TokenIcon'
import {
  MAXIMUM_L2_SPONSORED_DEPOSITS,
  SPONSORED_DEPOSIT_INTERVAL_DAYS,
} from '@lyra/web/constants/bridge'
import { DepositNetwork } from '@lyra/web/constants/chains'
import { DepositTokenId } from '@lyra/web/constants/tokens'
import { DepositQuote } from '@lyra/web/hooks/useDepositQuote'
import { formatDepositNetworkName } from '@lyra/web/utils/chains'
import {
  getGelatoMinDepositForSponsorship,
  getIsGelatoBridgeSupported,
} from '@lyra/web/utils/gelato'
import {
  formatDepositTokenBalance,
  formatDepositTokenSymbol,
  getIsL2,
  getTokenDecimals,
} from '@lyra/web/utils/tokens'
import { useCallback, useMemo } from 'react'
import { XStack } from 'tamagui'

type Props = {
  balances: Record<DepositTokenId, bigint>
  // Amount input
  depositAmount: bigint
  depositQuote?: DepositQuote
  error?: Error
  isDepositQuoteLoading?: boolean
  isInsufficientBalance?: boolean
  isForcedNative: boolean
  maxDepositAmount: bigint
  networks: DepositNetwork[]
  // Network input
  selectedNetwork: DepositNetwork
  // Token input
  selectedToken: DepositTokenId
  supportedTokens: DepositTokenId[]
  onChangeDepositAmount: (amount: bigint) => void
  onChangeNetwork: (network: DepositNetwork) => void
  onChangeToken: (token: DepositTokenId) => void
  onChangeIsForcedNative: (isNative: boolean) => void
}

const FEATURED_NETWORKS: DepositNetwork[] = [
  DepositNetwork.Arbitrum,
  DepositNetwork.Base,
  DepositNetwork.Optimism,
]

const FEATURED_TOKENS: DepositTokenId[] = [
  DepositTokenId.USDC,
  DepositTokenId.USDCe,
  DepositTokenId.USDT,
  DepositTokenId.ETH,
  DepositTokenId.WSTETH,
  DepositTokenId.WEETH,
  DepositTokenId.WBTC,
  DepositTokenId.LBTC,
  DepositTokenId.CBBTC,
  DepositTokenId.SDAI,
  DepositTokenId.SUSDE,
]

export default function DepositModalRows({
  networks,
  selectedNetwork,
  selectedToken,
  depositAmount,
  maxDepositAmount,
  depositQuote,
  isDepositQuoteLoading,
  error,
  balances,
  isInsufficientBalance,
  supportedTokens,
  isForcedNative,
  onChangeDepositAmount,
  onChangeNetwork,
  onChangeToken,
  onChangeIsForcedNative,
}: Props) {
  const isNativeAvailable = getIsGelatoBridgeSupported(selectedNetwork, selectedToken)

  const featuredTokens = useMemo(
    () => FEATURED_TOKENS.filter((token) => supportedTokens.includes(token)),
    [supportedTokens]
  )

  const formatInputValue = useCallback(
    (val: bigint) => formatDepositTokenBalance(val, selectedToken),
    [selectedToken]
  )

  return (
    <>
      <Section.YStack>
        <LabelText>Network</LabelText>
        <DropdownButton
          strategy="fixed"
          leftIcon={<NetworkIcon network={selectedNetwork} />}
          size="lg"
          label={selectedNetwork}
          textAlign="left"
        >
          {networks.map((network) => (
            <DropdownButton.ListItem
              icon={<NetworkIcon network={network} />}
              label={
                <SpanText textTransform="none">
                  {formatDepositNetworkName(network).toUpperCase()}
                </SpanText>
              }
              key={network}
              onPress={() => onChangeNetwork(network)}
              isSelected={network === selectedNetwork}
              size="lg"
            />
          ))}
        </DropdownButton>
        <XStack gap="$1" flexWrap="wrap">
          {FEATURED_NETWORKS.map((network) => (
            <Tag
              key={network}
              icon={<NetworkIcon network={network} />}
              label={network}
              onPress={() => onChangeNetwork(network)}
            />
          ))}
        </XStack>
      </Section.YStack>
      <Section.YStack>
        <LabelText>Token</LabelText>
        <DropdownButton
          strategy="fixed"
          leftIcon={<TokenIcon size={18} tokenId={selectedToken} />}
          size="lg"
          label={
            <SpanText textTransform="none">{formatDepositTokenSymbol(selectedToken)}</SpanText>
          }
          textAlign="left"
          textTransform="none"
        >
          {supportedTokens.map((token) => (
            <DropdownButton.ListItem
              key={token}
              size="lg"
              icon={<TokenIcon size={18} tokenId={token} />}
              label={<SpanText textTransform="none">{formatDepositTokenSymbol(token)}</SpanText>}
              sublabel={formatDepositTokenBalance(balances[token], token)}
              onPress={() => onChangeToken(token)}
              isSelected={selectedToken === token}
            />
          ))}
        </DropdownButton>
        <XStack flexWrap="wrap" gap="$1">
          {featuredTokens.map((depositToken) => (
            <Tag
              key={depositToken}
              label={formatDepositTokenSymbol(depositToken)}
              icon={<TokenIcon tokenId={depositToken} />}
              onPress={() => onChangeToken(depositToken)}
              textTransform="none"
            />
          ))}
        </XStack>
      </Section.YStack>
      <Section.YStack>
        <BigNumberInput
          label="Amount"
          rightLabel={`Available: ${formatDepositTokenBalance(
            balances[selectedToken],
            selectedToken
          )}`}
          decimals={getTokenDecimals(selectedToken)}
          flexGrow={1}
          flexShrink={1}
          formatValue={formatInputValue}
          size="lg"
          status={isInsufficientBalance && depositAmount ? 'error' : 'default'}
          value={depositAmount}
          onChangeValue={onChangeDepositAmount}
          rightContent={
            maxDepositAmount > BigInt(0) ? (
              <Button
                size="sm"
                label="Max"
                onPress={() => onChangeDepositAmount(maxDepositAmount)}
                isSelected={depositAmount !== BigInt(0) && depositAmount === maxDepositAmount}
              />
            ) : null
          }
        />
        {isNativeAvailable && !isForcedNative ? (
          <Notice
            status="info"
            message={`Deposit ${formatDepositTokenBalance(
              getGelatoMinDepositForSponsorship(selectedNetwork, selectedToken),
              selectedToken
            )} or more for no network fees. Valid ${
              getIsL2(selectedNetwork) ? `${MAXIMUM_L2_SPONSORED_DEPOSITS} times` : 'once'
            } every ${SPONSORED_DEPOSIT_INTERVAL_DAYS} days.`}
          />
        ) : null}
      </Section.YStack>
      <Section.YStack>
        {isNativeAvailable ? (
          <XStack justifyContent="space-between" alignItems="center">
            <TooltipText
              tooltipTitle="Fee Token"
              tooltipContent="Derive allows you to pay network fees in the deposit token so that you don't need to fund your wallet with gas (ETH)."
              label="Fee Token"
            />
            <XStack gap="$1" alignItems="center">
              <BodyText color="secondary">
                <Link
                  isActive={!isForcedNative}
                  onPress={() => onChangeIsForcedNative(false)}
                  label={formatDepositTokenSymbol(selectedToken)}
                />
                &nbsp;/&nbsp;
                <Link
                  isActive={isForcedNative}
                  onPress={() => onChangeIsForcedNative(true)}
                  label="ETH"
                />
              </BodyText>
              <Button
                icon={<ArrowLeftRight />}
                size="sm"
                onPress={() => onChangeIsForcedNative(!isForcedNative)}
              />
            </XStack>
          </XStack>
        ) : null}
        <XStack width="100%" alignItems="center" justifyContent="space-between">
          <TooltipText
            label="Network Fee"
            tooltipTitle={depositQuote && !depositQuote.isNative ? 'Network Fee' : undefined}
            tooltipContent={
              depositQuote && !depositQuote.isNative
                ? `The network fee is the gas fee required to execute your deposit transaction on the ${formatDepositNetworkName(
                    selectedNetwork
                  )} network. Derive converts ${selectedToken} to ETH to pay for gas.`
                : null
            }
          />
          {isDepositQuoteLoading || !depositQuote || error ? (
            <BodyText>...</BodyText>
          ) : !depositQuote.isNative && depositQuote.isSponsored ? (
            <BodyText color="secondary">
              Discount Applied&nbsp;
              <TextAmountUpdate
                prevAmountColor="red"
                newAmountColor="red"
                prevAmount={Math.max(
                  // Handle very small fee
                  0.0001,
                  bigNumberToNumberUNSAFE(depositQuote.fee, getTokenDecimals(depositQuote.feeToken))
                )}
                newAmount={0}
                format={(amount) =>
                  formatDepositTokenBalance(
                    toBigNumber(amount, getTokenDecimals(depositQuote.feeToken)),
                    depositQuote.feeToken
                  )
                }
              />
            </BodyText>
          ) : (
            <BodyText>
              {formatDepositTokenBalance(depositQuote.fee, depositQuote.feeToken)}
            </BodyText>
          )}
        </XStack>
      </Section.YStack>
    </>
  )
}
