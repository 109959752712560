import formatNumber from '@lyra/core/utils/formatNumber'
import formatPercentage from '@lyra/core/utils/formatPercentage'
import formatUSD from '@lyra/core/utils/formatUSD'
import React from 'react'

import { BodyTextSize, TextColor } from '../utils/text'
import BodyText from './BodyText'
import SpanText from './SpanText'

type AmountFormat = 'percentage' | 'usd'

type Props = {
  prevAmount: number
  newAmount: number
  decimals?: number
  format?: AmountFormat | ((amount: number) => string)
  newAmountPrefix?: string
  prevAmountPrefix?: string
  newAmountColor?: TextColor
  prevAmountColor?: TextColor
  showNewIfZero?: boolean
  size?: BodyTextSize
}

const TextAmountUpdate = ({
  prevAmount,
  newAmount,
  decimals,
  format,
  newAmountPrefix,
  prevAmountPrefix,
  newAmountColor,
  prevAmountColor,
  showNewIfZero,
  size = 'md',
}: Props) => {
  const boundFormatNumber = (amount: number) => formatNumber(amount, { dps: decimals })
  const boundFormatPercentage = (amount: number) => formatPercentage(amount)

  const formatter =
    typeof format === 'function'
      ? format
      : format === 'percentage'
      ? boundFormatPercentage
      : format === 'usd'
      ? formatUSD
      : boundFormatNumber
  const formattedPrevAmount = formatter(prevAmount)
  const formattedNewAmount = formatter(newAmount)

  const prevAmountPrefixWithSpace = prevAmountPrefix ? `${prevAmountPrefix} ` : ''
  const newAmountPrefixWithSpace = newAmountPrefix ? `${newAmountPrefix} ` : ''

  const showNewAmount = (showNewIfZero || newAmount !== 0) && prevAmount !== newAmount

  return (
    <BodyText size={size}>
      <SpanText
        textDecorationLine={
          prevAmount !== 0 && prevAmount !== newAmount ? 'line-through' : undefined
        }
        color={showNewAmount ? (prevAmountColor ? prevAmountColor : newAmountColor) : 'primary'}
      >
        {prevAmountPrefixWithSpace}
        {formattedPrevAmount}
      </SpanText>
      {showNewAmount ? (
        <SpanText color={newAmountColor}>
          &nbsp;→&nbsp;
          {newAmountPrefixWithSpace}
          {formattedNewAmount}
        </SpanText>
      ) : null}
    </BodyText>
  )
}

export default TextAmountUpdate
