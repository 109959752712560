import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

import { InstrumentType } from '../constants/instruments'
import { MarketId } from '../constants/markets'
import { getActiveOptionMarketIds, getActivePerpMarketIds, getMarketId } from '../utils/markets'
import useIsOptionsPage from './useIsOptionsPage'
import useIsPerpsPage from './useIsPerpsPage'

const getActiveMarketIds = (instrumentType: InstrumentType): MarketId[] => {
  switch (instrumentType) {
    case InstrumentType.Options:
      return getActiveOptionMarketIds()
    case InstrumentType.Perps:
      return getActivePerpMarketIds()
    default:
      return []
  }
}

export default function useSelectedMarket(): MarketId | null {
  const pathname = usePathname()

  const isPerps = useIsPerpsPage()
  const isOptions = useIsOptionsPage()

  const instrumentType = isPerps
    ? InstrumentType.Perps
    : isOptions
    ? InstrumentType.Options
    : undefined

  return useMemo(() => {
    const activeMarketIds = instrumentType ? getActiveMarketIds(instrumentType) : []
    const market = getMarketId(pathname.split('/')[2] ?? '')
    return market && activeMarketIds.includes(market) ? market : activeMarketIds[0]
  }, [instrumentType, pathname])
}
