import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

import { InstrumentType } from '../constants/instruments'
import { CollateralId } from '../constants/tokens'
import { getActiveSpotMarketIds, getCollateralId } from '../utils/markets'
import useIsSpotPage from './useIsSpotPage'

export default function useSelectedCollateral(): CollateralId | null {
  const pathname = usePathname()
  const isSpot = useIsSpotPage()

  const instrumentType = isSpot ? InstrumentType.Spot : undefined

  return useMemo(() => {
    const activeCollateralIds = instrumentType ? getActiveSpotMarketIds() : []
    const collateral = getCollateralId(pathname.split('/')[2] ?? '')

    return collateral && activeCollateralIds.includes(collateral)
      ? collateral
      : activeCollateralIds[0]
  }, [instrumentType, pathname])
}
