import BodyText from '@lyra/core/components/BodyText'
import Button from '@lyra/core/components/Button'
import Icon, { AlertTriangle, X } from '@lyra/core/components/Icon'
import Responsive from '@lyra/core/components/Responsive'
import useLiveIncidents from '@lyra/web/hooks/useLiveIncidents'
import { useMemo } from 'react'
import { XStack } from 'tamagui'

export default function NavUptimeBanner() {
  const { incidents, dismissIncident } = useLiveIncidents()
  const highestSeverityIncident = useMemo(
    () =>
      incidents?.sort((a, b) => (a.severity === 'high' ? -1 : a.severity === 'medium' ? 0 : 1))[0],
    [incidents]
  )

  return highestSeverityIncident ? (
    <XStack
      paddingHorizontal="$3"
      paddingVertical="$1.5"
      alignItems="center"
      width="100%"
      backgroundColor="$amberBg"
    >
      <Responsive desktop={<Icon color="amber" size={16} icon={<AlertTriangle />} />} />
      <BodyText marginHorizontal="auto" color="amber" textAlign="center">
        {highestSeverityIncident.message}
      </BodyText>
      {highestSeverityIncident.severity !== 'high' ? (
        <Button
          size="sm"
          isTransparent
          icon={<X />}
          onPress={() => dismissIncident(highestSeverityIncident)}
        />
      ) : null}
    </XStack>
  ) : null
}
