'use client'

import useIsMobile from '@lyra/core/hooks/useIsMobile'
import isServer from '@lyra/core/utils/isServer'
import { useEffect } from 'react'
import { IntercomProvider as _IntercomProvider, useIntercom } from 'react-use-intercom'

import useAuth from '../hooks/useAuth'
import useShowIntercom from '../hooks/useShowIntercom'

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID
export const isIntercomLoaded = !!INTERCOM_APP_ID

const IntercomInternal = () => {
  const isMobile = useIsMobile()

  const showIntercom = useShowIntercom()
  const { user } = useAuth()
  const { boot, shutdown, update } = useIntercom()

  useEffect(() => {
    if (!isServer && !isMobile && showIntercom) {
      try {
        boot({
          userId: user?.address,
        })
      } catch (error) {
        console.error(error)
      }
    }
    return () => {
      shutdown()
    }
  }, [user, boot, shutdown, isMobile, showIntercom])

  useEffect(() => {
    update({ userId: user?.address })
  }, [user?.address, isMobile, update, shutdown])

  return <></>
}

export default function IntercomProvider({ children }: { children: React.ReactNode }) {
  return INTERCOM_APP_ID ? (
    <_IntercomProvider appId={INTERCOM_APP_ID}>
      {children}
      <IntercomInternal />
    </_IntercomProvider>
  ) : (
    children
  )
}
